<template>
  <div>
    <CRow class="pb-4 mt-2">
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="FromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="ToDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
            <CButton
              color="watch"
              square
              size="sm"
              class="mx-1"
              @click="YardCurrentStatisticsReport"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              @click="Clear"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import moment from 'moment';
import ContainerTypeBar from './container-type-bar';
import ContainerTypeDonut from './container-type-donut';
import SimpleChartBar from './simple-chart-bar';
import SimplePie from "./simple-pie";
//DATA-------------------------
function data() {
  return {
    FromDay: '',
    ToDay: '',
    previousDate: '',
    laterDate: '',
  }
}

//methods
async function getYardCargoReception() {
  /* await this.$http.get('YardCargoReception-by-YardId', { YardId: this.yardData.YardId })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })*/
}

async function YardCurrentStatisticsReport() {
  /*this.$store.state.yardManagement.loading = true;
  let ReportJson = {
    YardId: this.yardData.YardId,
    DateStart: this.FromDay ? DateFormater.formatDateTimeWithoutSlash(this.FromDay) : '',
    DateEnd: this.ToDay ? DateFormater.formatDateTimeWithoutSlash(this.ToDay) : '',
  }
  await this.$http.post('YardCurrentStatisticsReport-by-YardId', ReportJson, { root: 'ReportJson' })
    .then(response => {
      let CurrentStatistics = response.data.data ?? [];
      if(CurrentStatistics && CurrentStatistics.length != 0) {
        
      }

    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    })*/
}

async function Clear() {
  this.$store.state.yardManagement.loading = true;
  await this.getDateRange();
  //await this.YardCurrentStatisticsReport();
}

function getDateRange(){
  this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
  this.laterDate = new Date();
  this.FromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
  this.ToDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

async function Resest() {
  
}

//computed

export default {
  name: 'yard-situation-index',
  components: {
    ContainerTypeBar,
    ContainerTypeDonut,
    SimpleChartBar,
    SimplePie,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
    YardCurrentStatisticsReport,
    getYardCargoReception,
    Clear,
    getDateRange,
    validateDateRange,
    Resest,
  },
  computed: {
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      currentTab: state => state.yardManagement.tabIndex,
      currentPrincipalTab: state => state.yardManagement.yardCollapse,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      YardSelected: state => state.yardManagement.YardSelected,
      yardList: state => state.yardManagement.yardList,
      isSelectYard: state => state.yardManagement.isSelectYard,
    })
  },
  watch: {
    currentTab: async function(newVal) {
      if(newVal == 0 && this.currentPrincipalTab == 1 && !this.isSelectYard) {
        this.$store.state.yardManagement.loading = true;
        await this.Resest();
        await this.getDateRange();
        await this.YardCurrentStatisticsReport();
        this.$store.state.yardManagement.loading = false;
      }
    },
    currentPrincipalTab: async function(newVal, oldVal) {
      if(newVal == 1 && oldVal == 0) {
        this.$store.state.yardManagement.loading = true;
        await this.Resest();
        await this.getDateRange();
        await this.getYardCargoReception();
        await this.YardCurrentStatisticsReport()
        this.$store.state.yardManagement.loading = false;
        this.$store.state.yardManagement.isSelectYard = false;
      }
    },
    YardSelected: async function (NewVal, oldVal) {
        let YardDataNew = this.yardList.find(item => item.YardId == NewVal);
        let YardDataOld = this.yardList.find(item => item.YardId == oldVal);
        if (YardDataNew && YardDataOld) {
          this.$store.state.yardManagement.loading = true;
          await this.Resest();
          await this.getDateRange();
          await this.getYardCargoReception();
          await this.YardCurrentStatisticsReport();
          this.$store.state.yardManagement.loading = false;
          this.$store.state.yardManagement.isSelectYard = false;
        }
      }
  }
};
</script>
<style lang="scss">
/* apexcharts -- quitar la opcion de descargar del componente */
.apexcharts-toolbar{
  display: none !important;
}
</style>
<style scoped>
.title{
  font-weight: 700;
  color: #60656e;
  margin-bottom: 0 !important;
}
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  border-radius: 5px;
}
.block-white{
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 112, 192);
}
.block-white .text-blue{
  color: rgb(0, 112, 192);
  font-weight: 400 !important;
}
</style>