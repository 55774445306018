<template>
	<div class="col-sm-12" style="height: 100%;">
		<apexchart :width="Width" :height="Height" type="donut" :options="chartOptions" :series="Series"></apexchart>
	</div>
</template>
<script>
	function chartOptions(){
	return {
		chart: {
			type: 'donut',
			animations: {
				enabled: true,
			}
		},
		colors: this.Colors.length!=0?this.Colors:undefined,
    labels: this.Categories,
		stroke: {
			width: 0,
		},
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					size: '45%'
				},
			},
		},
		legend: {
			show: false
		},
		tooltip: {
			enabled: true,
		},
		dataLabels: {
			enabled: true,
			style: {
				fontSize: '9px',
				colors: ["#ffffff"]
			},
			dropShadow: {
					enabled: false,
			},
		},
	}    
}

export default {
	name:'container-type-donut',
	props: {
		Series: {
			type: Array,
			require: true,
			default: () => [],
		},
		Categories:{
			type: Array,
			require: true,
			default: () => [],
		},
		Colors:{
			type: Array,
			require: false,
			default: () => [],
		},
		Width:{
			type: String,
			require: true,
			default: 'auto',
		},
		Height:{
			type: String,
			require: true,
			default: 'auto',
		},
	},
	methods:{
	},
	computed:{
		chartOptions
	},
}
</script>