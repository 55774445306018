<template>
	<div class="col-sm-12" style="height: 100%;">
		<apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
	</div>
</template>
<script>
	function chartOptions(){
	return {
		chart: {
			type: 'bar',
			animations: {
				enabled: true,
			}
		},
		colors: this.Colors,
		xaxis: {
			categories: this.Categories,
			labels: {
				style: {
					colors: '#60656e',
				}
			},
			axisBorder: {
				color: '#60656e',
			},
			axisTicks: {
				color: '#60656e',
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '80%',
			}
		},
		stroke: {
			show: true,
			width: 4,
			colors: ['transparent']
		},
		grid: {
			show: true,
			borderColor: '#60656e',
		},
		legend: {
			show: true,
			labels: {
				colors: '#60656e',
			},
			onItemClick: {
				toggleDataSeries: false,
			}
		},
		tooltip: {
			enabled: true,
			position: 'bottom',
      horizontalAlign: 'center'
		},
		dataLabels: {
			enabled: false,
		},
		yaxis: {
			labels: {
				show: true,
				style: {
					colors: '#60656e',
				}
			}
		},
	}    
}

export default {
	name:'container-type-bar',
	props: {
		Series: {
			type: Array,
			require: true,
			default: () => [],
		},
		Categories:{
			type: Array,
			require: true,
			default: () => [],
		},
		Colors:{
			type: Array,
			require: false,
			default: () => [],
		},
		Width:{
			type: String,
			require: true,
			default: 'auto',
		},
		Height:{
			type: String,
			require: true,
			default: 'auto',
		},
	},
	methods:{
	},
	computed:{
		chartOptions
	},
}
</script>